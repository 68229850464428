<template>
  <div class="infoPage">
<!--    <div class="pageNav">-->
<!--      <div class="nav_left">-->
<!--        <span class="zh">票务预约</span>-->
<!--        <span class="en">Ticket Reservation</span>-->
<!--      </div>-->
<!--      <div class="nav_right">-->
<!--        <div class="n_r_title">您当前的位置:</div>-->
<!--        <div class="nav_lan">-->
<!--          <el-breadcrumb separator-class="el-icon-arrow-right">-->
<!--            &lt;!&ndash;          <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>&ndash;&gt;-->
<!--            <el-breadcrumb-item>首页</el-breadcrumb-item>-->
<!--            <el-breadcrumb-item>票务预约</el-breadcrumb-item>-->
<!--          </el-breadcrumb>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
    <About v-show="titleid==0?true:false" />
    <Combination v-show="titleid==1?true:false" />
    <Sightseeing v-show="titleid==2?true:false" />
  </div>
</template>

<script>
// import store from '@/store'
// import Pagingdevice from '@/components/Pagingdevice'
export default {
  name: "ticketingabout",
  components:{
    // Pagingdevice,
    // eslint-disable-next-line vue/no-unused-components
    About:()=>import('./about'),
    // eslint-disable-next-line vue/no-unused-components
    Sightseeing:()=>import('./sightseeing'),
    // eslint-disable-next-line vue/no-unused-components
    Combination:()=>import('./combination'),
  },
  data(){
    return {
      list:[
        {id:0,img:require('../../../public/img/swiperPage/LYGLT.png'),title:'标题',text:'这是详情'},
        {id:1,img:require('../../../public/img/swiperPage/LYGLT.png'),title:'标题',text:'这是详情'},
        {id:2,img:require('../../../public/img/swiperPage/LYGLT.png'),title:'标题',text:'这是详情'},
        {id:3,img:require('../../../public/img/swiperPage/LYGLT.png'),title:'标题',text:'这是详情'},
        {id:4,img:require('../../../public/img/swiperPage/LYGLT.png'),title:'标题',text:'这是详情'},
        {id:5,img:require('../../../public/img/swiperPage/LYGLT.png'),title:'标题',text:'这是详情'},
      ],
      titleid:2,
      titlelsit:[
        {id:0,title:'景点门票'},
        {id:1,title:'组合门票'},
        {id:2,title:'游船票'},
      ]
    }
  },
  watch:{

  },
  created() {
    // sessionStorage.setItem('RightImg',require('../../../public/img/swiperPage/DTZXCBT.png'))
  },
  mounted() {
    if(this.$route.query.type){
      this.titleid = this.$route.query.type
    }
  },
  methods:{
    titlebtn(id){
      console.log(id)
    }
  }
}
</script>

<style scoped lang="less">
.nav{
  display: flex;
  width: 450px;
  margin: 10px auto;
  .nav_nav{
    width: 150px;
    height: 60px;
    line-height: 60px;
    text-align: center;
    cursor: pointer;
    font-weight: 700;
  }
}
.show{
  font-weight: 700;
  color: rgba(51, 125, 141, 1);
  background: rgba(51, 125, 141, 0.15);
  border-bottom: 2px #337D8D solid;
}
.infoPage{
  width: 100%;
  //height: calc(100vh - 130px);
  //background: skyblue;
  //padding: 0 0 0 55px;
  .pageNav{
    height: 88px;
    border-bottom: 1px solid #E0E0E0;
    //margin-bottom: 50px;
    padding: 0 110px 0 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .nav_left{
      margin-top: 20px;
      .zh{
        font-size: 24px;
        font-family: STSongti-SC-Black, STSongti-SC;
        font-weight: 900;
        color: #313131;
        //line-height: 33px;
        margin-right: 12px;
      }
      .en{
        font-size: 20px;
        font-family: STSongti-SC-Regular, STSongti-SC;
        font-weight: 400;
        color: #313131;
        //line-height: 28px;
      }
    }
    .nav_right{
      display: flex;
      align-items: center;
      //justify-content: center;
      .n_r_title{
        margin-top: 20px;
        font-size: 18px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #313131;
        line-height: 25px;
        margin-right: 20px;
      }
      /deep/.el-breadcrumb{
        font-size: 18px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #313131;
        line-height: 25px;
      }
    }
    .nav_lan{
      margin-top: 20px;
    }
    .nav_btn{
      width: 582px;
      height: 70px;
      display: flex;
      background: rgba(170, 170, 170, 0.15);
      font-size: 26px;
      cursor:pointer;
      margin-top: 16px;
      margin-left: 20px;
      .nav_btn_1{
        width: 194px;
        height: 70px;
        text-align: center;
        line-height: 70px;
        &:hover{
          color: #337D8D;
          background: rgba(51, 125, 141, 0.15);
          border-bottom: 2px #337D8D solid;
        }
      }
    }
  }
}
</style>
