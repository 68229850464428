import { render, staticRenderFns } from "./ticketingabout.vue?vue&type=template&id=266ddb5d&scoped=true&"
import script from "./ticketingabout.vue?vue&type=script&lang=js&"
export * from "./ticketingabout.vue?vue&type=script&lang=js&"
import style0 from "./ticketingabout.vue?vue&type=style&index=0&id=266ddb5d&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.9.8@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "266ddb5d",
  null
  
)

export default component.exports